import React from "react";
import InstantNewsLogo from "../imageComponents/instantNewsLogo";
import styles from "../../styles/providers-styles/providers-header.module.scss";

const ProvidersHeader = ({ btnLink }) => {
  return (
    <div className="d-flex justify-content-between align-items-center p-2">
      <div className={`${styles.logo}`}>
        <InstantNewsLogo />
      </div>
      <a href={btnLink} className={styles.addToChrome}>
        <p>ADD TO CHROME</p>
      </a>
    </div>
  );
};

export default ProvidersHeader;
