import React from "react";
import styles from "../../styles/providers-styles/providers-first-section.module.scss";
import sharedStyles from "../../styles/providers-styles/shared.module.scss";
import Btn from "../../components/shared/btn";

import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";

const FreeDownload = ({ FreeDownloadImage, btnLink }) => {
  const { polkaDotPatternImage } = useStaticQuery(graphql`
    query {
      polkaDotPatternImage: file(relativePath: { eq: "polka-dot-pattern.png" }) {
        childImageSharp {
          fluid(quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="container-fluid p-0">
      <BackgroundImage fluid={polkaDotPatternImage.childImageSharp.fluid}>
        <div className="d-flex flex-wrap pt-5">
          <div className={`col-6 pl-0`}>
            <FreeDownloadImage />
          </div>
          <div
            className={`col-6 pl-5 d-flex flex-column align-items-end justify-content-center ${styles.sectionText}`}
          >
            <span className="align-self-start ml-5">
              <p className="h1 text-nowrap">
                Download For <br /> Free Today
              </p>
              <div className={"col-12 p-0 mt-4"}>
                <Btn
                  style={`text-nowrap ${sharedStyles.addToChromeBtn}`}
                  text="Add to Chrome - It's Free"
                  link={btnLink}
                />
              </div>
            </span>
          </div>
        </div>
      </BackgroundImage>
    </div>
  );
};

export default FreeDownload;
