import React from "react";

import AbcNews from "./abcNews";
import BbcWorldNews from "./bbcWorldNews";
import BusinessInsider from "./businessInsider";
import BuzzFeed from "./buzzFeed";
import CbsNews from "./cbsNews";
import CbsSports from "./cbsSports";
import ChicagoTribune from "./chicagoTribune";
import CnnNews from "./cnnNews";
import DailyMail from "./dailyMail";
import EliteDaily from "./eliteDaily";
import Espn from "./espn";
import Fortune from "./fortune";
import FoxNews from "./foxNews";
import FoxSportNews from "./foxSportNews";
import Gnn from "./gnn";
import HollywoodLife from "./hollywoodLife";
import LosAngelesTimes from "./losAngelesTimes";
import Mashable from "./mashable";
import MensJournal from "./mensJournal";
import Msnbc from "./msnbc";
import NbcNews from "./nbcNews";
import NewYorkPost from "./newYorkPost";
import NewYorkTimes from "./newYorkTimes";
import RollingStone from "./rollingStone";
import SheKnows from "./sheKnows";
import TheHuffingtonPost from "./theHuffingtonPost";
import UsWeekly from "./usWeekly";
import Variety from "./variety";
import WallStreetJournal from "./wallStreetJournal";
import YahooSportsNews from "./yahooSportsNews";

export {
  AbcNews,
  BbcWorldNews,
  BusinessInsider,
  BuzzFeed,
  CbsNews,
  CbsSports,
  ChicagoTribune,
  CnnNews,
  DailyMail,
  EliteDaily,
  Espn,
  Fortune,
  FoxNews,
  FoxSportNews,
  Gnn,
  HollywoodLife,
  LosAngelesTimes,
  Mashable,
  MensJournal,
  Msnbc,
  NbcNews,
  NewYorkPost,
  NewYorkTimes,
  RollingStone,
  SheKnows,
  TheHuffingtonPost,
  UsWeekly,
  Variety,
  WallStreetJournal,
  YahooSportsNews,
};

export const allLogos = [
  <CnnNews />,
  <NewYorkTimes />,
  <FoxNews />,
  <BuzzFeed />,
  <Espn />,
  <AbcNews />,
  <BbcWorldNews />,
  <BusinessInsider />,
  <CbsNews />,
  <CbsSports />,
  <ChicagoTribune />,
  <DailyMail />,
  <EliteDaily />,
  <Fortune />,
  <FoxSportNews />,
  <Gnn />,
  <HollywoodLife />,
  <LosAngelesTimes />,
  <Mashable />,
  <MensJournal />,
  <Msnbc />,
  <NbcNews />,
  <NewYorkPost />,
  <RollingStone />,
  <SheKnows />,
  <TheHuffingtonPost />,
  <UsWeekly />,
  <Variety />,
  <WallStreetJournal />,
  <YahooSportsNews />,
];
