import React from "react";
import styles from "../../styles/providers-styles/providers-second-section.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import WeatherBugIcon from "../imageComponents/weatherBugIcon";
import LiveNewsIcon from "../imageComponents/liveNewsIcon";
import ClickIcon from "../imageComponents/clickIcon";
import CustomizableSettingIcon from "../imageComponents/customizableSetting";

const SecondSection = () => {
  const { polkaDotPatternImage } = useStaticQuery(graphql`
    query {
      polkaDotPatternImage: file(relativePath: { eq: "polka-dot-pattern.png" }) {
        childImageSharp {
          fluid(quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="container-fluid p-0">
      <div className="d-flex flex-wrap">
        <BackgroundImage fluid={polkaDotPatternImage.childImageSharp.fluid}>
          <div
            className={`d-flex flex-wrap text-nowrap justify-content-center ${styles.keyFeaturesContainer}`}
          >
            <div className={`col-6 col-lg-5 d-flex justify-content-center mb-3 ${styles.feature}`}>
              <p className={styles.keyFeaturesTitle}>Key Features</p>
            </div>
            <div className="col-6 col-lg-5 mb-3"></div>
            <div className={`col-6 col-lg-5 d-flex justify-content-center ${styles.feature}`}>
              <span>
                <div className={`mb-3 ${styles.featureIcon}`}>
                  <LiveNewsIcon />
                </div>
                <p>Live News</p>
                <p>
                  Receive headlines in real-time from <br />
                  your favorite news outlets.
                </p>
              </span>
            </div>
            <div className={`col-6 col-lg-5  d-flex justify-content-center ${styles.feature}`}>
              <span>
                <div className={`mb-3 ${styles.featureIcon}`}>
                  <WeatherBugIcon />
                </div>
                <p>Local Weather</p>
                <p>
                  Stay updated with live weather forecasts in <br />
                  your area.
                </p>
              </span>
            </div>
            <div className={`col-6 col-lg-5 d-flex justify-content-center mt-5 ${styles.feature}`}>
              <span>
                <div className={`mb-3 ${styles.featureIcon}`}>
                  <ClickIcon />
                </div>
                <p>One-Click Navigation</p>
                <p>
                  Get the full story directly from the source <br />
                  by simply clicking on the headline.
                </p>
              </span>
            </div>
            <div className={`col-6 col-lg-5  d-flex justify-content-center mt-5 ${styles.feature}`}>
              <span>
                <div className={`mb-3 ${styles.featureIcon}`}>
                  <CustomizableSettingIcon />
                </div>
                <p>Customizable Experience</p>
                <p>
                  From selecting your news line-up to changing <br />
                  your weather location, you choose how you <br />
                  want the news.
                </p>
              </span>
            </div>
          </div>
        </BackgroundImage>
      </div>
    </div>
  );
};

export default SecondSection;
