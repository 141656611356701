import React from "react";
import styles from "../../styles/providers-styles/shared.module.scss";

import { allLogos } from "../imageComponents/news-sources-carousel-images";

const NewsProvidersCarousel = () => {
  const logosPerSlide = 6;
  const logosCount = 31;

  const renderSlide = startIndex => {
    const cols = [];

    for (let i = startIndex; i < startIndex + logosPerSlide && i < logosCount; i++) {
      if (allLogos[i]) {
        let col = (
          <div key={i} className="col-4 d-flex justify-content-center my-3">
            <div className={`border ${styles.carouselLogo}`}>{allLogos[i]}</div>
          </div>
        );
        cols.push(col);
      }
    }
    return cols;
  };

  return (
    <div className={`container-fluid p-0 ${styles.carousel}`}>
      <p> Access Top Headlines from ...</p>
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className={`carousel-inner d-flex align-items-center ${styles.innerCarousel}`}>
          <div className="carousel-item active">
            <div className="d-flex flex-wrap px-5 align-items-center">{renderSlide(0)}</div>
          </div>
          <div className="carousel-item">
            <div className="d-flex flex-wrap px-5 align-items-center">{renderSlide(6)}</div>
          </div>
          <div className="carousel-item">
            <div className="d-flex flex-wrap px-5 align-items-center">{renderSlide(13)}</div>
          </div>
          <div className="carousel-item">
            <div className="d-flex flex-wrap px-5 align-items-center">{renderSlide(19)}</div>
          </div>
          <div className="carousel-item">
            <div className="d-flex flex-wrap px-5 align-items-center">{renderSlide(25)}</div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
};

export default NewsProvidersCarousel;
