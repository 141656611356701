import React from "react";
import styles from "../../styles/providers-styles/providers-first-section.module.scss";
import sharedStyles from "../../styles/providers-styles/shared.module.scss";
import Btn from "../../components/shared/btn";
import PoweredByWeatherBugImage from "../../components/imageComponents/poweredByWeatherBug";

const FirstSection = ({ btnLink }) => {
  return (
    <div className="container-fluid p-0">
      <div className="d-flex flex-wrap">
        <div className={`col-6 pl-0`}>
          <div className={styles.firstSectionImage}>
            <PoweredByWeatherBugImage />
          </div>
        </div>
        <div
          className={`col-6 pr-0 d-flex flex-column align-items-end justify-content-center ${styles.sectionText}`}
        >
      
          <span className={`align-self-start ${styles.text}`}>
            <p className="h1 mb-4">
              Never Miss <br /> An Update
            </p>
            <p className="text-nowrap">
              Instantly access the latest news,
              <br /> sports, and weather from top
              <br /> sources right in your browser.
            </p>
            <div className={`text-nowrap  ${styles.add2ChromeItsFree}`}>
              <Btn
                style={sharedStyles.addToChromeBtn}
                text="Add to Chrome - It's Free"
                link={btnLink}
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
