import React from "react";
import styles from "../../styles/providers-styles/shared.module.scss";

const ProvidersFooter = ({ privacyLink, termsLink }) => {
  return (
    <div className={`container-fluid p-0 ${styles.providersFooter}`}>
      <div className="d-flex flex-wrap px-0 py-3 justify-content-center align-items-center">
        <p className="m-0">
          <a href={privacyLink}>
            PRIVACY
          </a>
        </p>
      </div>
    </div>
  );
};

export default ProvidersFooter;
