import React from "react";

const Btn = ({ text, style, link }) => {
  return (
    <a href={link} className={`d-flex justify-content-center ${style}`}>
      {text}
    </a>
  );
};

export default Btn;
