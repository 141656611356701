import React from "react";
import styles from "../../styles/providers-styles/lane-divider.module.scss";
import sharedStyles from "../../styles/providers-styles/shared.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import StayInTheKnow from "../imageComponents/stayInTheKnow";
import Btn from "../shared/btn";

const LineDivider = ({btnLink}) => {
  const { bgImage } = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "blueBanner.png" }) {
        childImageSharp {
          fluid(quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="container-fluid p-0">
      <BackgroundImage fluid={bgImage.childImageSharp.fluid}>
        <div className={`d-flex flex-wrap justify-content-center align-items-center ${styles.laneDividerRow}`}>
          <div className={styles.stayInTheKnow}>
            <StayInTheKnow />
          </div>
          <Btn style={`ml-5 text-nowrap ${sharedStyles.addToChromeBtn}`} text="Add to Chrome - It's Free" link={btnLink} />
        </div>
      </BackgroundImage>
    </div>
  );
};

export default LineDivider;
